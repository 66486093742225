<script>
import def from "@/components/business/time/eventsAdd";
export default {
  extends: def,
  methods: {
    getEvents() {
      return this.$store.state.privateEvent.unsaved;
    },
    setSave(data) {
      this.$store.commit("privateEvent/prefetch", data);
    }
  }
};
</script>
